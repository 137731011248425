import React from 'react';
import {  
    Grid, 
    Typography,
    IconButton,
    Card,
    CardContent, Box, Button, styled
} from "@mui/material";
// icons
import InventoryIcon from '@mui/icons-material/Inventory';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// components
import Title from './Title'
import Paragraph from './Paragraph'

const Content = () => {

    const CustomBox = styled(Box) (({ theme }) => ({
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(10),
        backgroundColor: '#060f5e',
    }));

    const GridContainer = styled(Grid)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '20px',
    });

    return (    
        <CustomBox>
            <Typography 
                variant='h4'
                component='h3'
                sx={{ 
                    fontWeight: '700',
                    textAlign: 'center',
                    color: 'white'
                }}
            >
                How It Works
            </Typography>
            <Typography
                sx={{
                    maxWidth: '75%',
                    mx: 0,
                    textAlign: 'center',
                    py: 3,
                    color: 'white',
                }}
            >
                Our modular system integrates seamlessly with existing mobile robots. We provide modules such as the cabin and lift modules that can be easily mounted onto any robot platform. These modules are designed to rapidly adapt to various tasks and applications, allowing robots to handle diverse roles within healthcare settings. By using our modules, hospitals can enhance their robotic solutions' versatility and efficiency while minimizing the costs and complexities of switching between different systems.
            </Typography>
            <GridContainer container spacing={0}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card 
                        square={ true }
                        sx={{
                            minHeight: 200,
                            display: 'flex',
                            flexDirection:'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            border: '1px solid #ccc',
                        }}
                    >
                        <CardContent>
                            <IconButton>
                                <AttachMoneyIcon 
                                    fontSize="large"
                                    color="secondary"
                                />
                            </IconButton>
                            <Typography 
                                variant="h5" 
                                component="p"
                                sx={{
                                    fontWeight: 700,
                                    textTransform: 'capitalize',
                                }}
                            >
                                Lower Switching Cost
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Card 
                        square={ true }
                        sx={{ 
                            minHeight: 200,
                            display: 'flex',
                            flexDirection:'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center', 
                            border: '1px solid #ccc'
                        }}
                    >
                        <CardContent>
                            <IconButton>
                                <SettingsIcon
                                    fontSize="large"
                                    color="secondary"
                                />
                            </IconButton>
                            <Typography 
                                variant="h5" 
                                component="p"
                                sx={{
                                    fontWeight: 700,
                                    textTransform: 'capitalize',
                                }}
                            >
                                System Integration
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Card 
                        square={ true }
                        sx={{ 
                            minHeight: 200,
                            display: 'flex',
                            flexDirection:'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',                    
                            border: '1px solid #ccc',
                        }}
                    >
                        <CardContent>
                            <IconButton>
                                <AutoAwesomeMotionIcon
                                    fontSize="large"
                                    color="secondary"
                                />
                            </IconButton>
                            <Typography 
                                variant="h5" 
                                component="p"
                                sx={{
                                    fontWeight: 700,
                                    textTransform: 'capitalize',
                                }}
                            >
                                Versatile Task Adaptation
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Card 
                        square={ true }
                        sx={{ 
                            minHeight: 200,
                            display: 'flex',
                            flexDirection:'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center', 
                            border: '1px solid #ccc',
                        }}
                    >
                        <CardContent>
                            <IconButton>
                                <AccessTimeIcon
                                    fontSize="large"
                                    color="secondary"
                                />
                            </IconButton>
                            <Typography 
                                variant="h5" 
                                component="p"
                                sx={{
                                    fontWeight: 700,
                                    textTransform: 'capitalize',
                                }}
                            >
                                Rapid Adaptation
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </GridContainer>
        </CustomBox>
    );
}

export default Content;
