import React from 'react';
import {Box, Stack } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Title from './Title';
import logoIF from '../assets/logo/IF.png';
import logoTF from '../assets/logo/TF.png';
import logoCITM from '../assets/logo/CITM.png';
import logoNvidia from '../assets/logo/Nvidia.png';

const Partners = () => {
    return (
        <Stack
            sx={{
                py: 8,
                px: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Box
                component='section'
                sx={{
                    paddingBottom: 3,
                    textAlign: 'center'
                }}
            >
                <Title 
                    text={'Partners'}
                    textAlign={'center'}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
                    <img src={logoIF} alt="logoIF" width={'20%'} />
                    <img src={logoTF} alt="logoTF" width={'20%'} />
                    <img src={logoCITM} alt="logoCITM" width={'15%'} />
                    <img src={logoNvidia} alt="logoNvidia" width={'20%'} />
                </Box>
            </Box>
        </Stack>
    );
};

export default Partners;
