import React from 'react';
import { Box, Button, styled, Typography } from "@mui/material";
import { Link } from 'react-router-dom';

import headerImg from '../assets/MiR200.png';

const Header = () => {

    const CustomBox = styled(Box)(({ theme }) => ({
        minHeight: '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(10),
        backgroundColor: '#060f5e',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            textAlign: 'center',
        }
    }));

    const BoxText = styled(Box)(({ theme }) => ({
        flex: '1',
        paddingLeft: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    }));

    const ImageBox = styled(Box)(({ theme }) => ({
        flex: '2',
        alignSelf: 'center',
        marginLeft: '50px', // Adjust the margin to shift the image to the right
        paddingTop: '30px', // Adjust vertical alignment if necessary
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginTop: theme.spacing(4),
        },
    }));

    return (
        <CustomBox component='header'>
            <BoxText component='section'>
                <Typography
                    variant='h2'
                    component='h1'
                    sx={{
                        fontWeight: 700,
                        color: '#fff',
                        textAlign: 'center', // Center align the text
                    }}
                >
                    Mobile Robot Modules
                </Typography>

                <Typography
                    variant='p'
                    component='p'
                    sx={{
                        py: 3,
                        lineHeight: 1.6,
                        color: '#fff',
                        textAlign: 'center', // Center align the text
                    }}
                >
                    Enhance the capabilities of existing robotics by enabling quick adaptation to any application, 
                    <br/>
                    while lowering switching costs for a more efficient and versatile solution.
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        component={Link}
                        to={'/about'}
                        variant='outlined'
                        sx={{
                            px: 4,
                            py: 1,
                            fontSize: '0.9rem',
                            textTransform: 'capitalize',
                            borderRadius: 0,
                            borderColor: '#14192d',
                            color: '#fff',
                            backgroundColor: '#14192d',
                            "&&:hover": {
                                backgroundColor: "#343a55"
                            },
                            "&&:focus": {
                                backgroundColor: "#343a55"
                            }
                        }}
                    >
                        explore
                    </Button>
                </Box>
            </BoxText>

            {/* <ImageBox>
                <img
                    src={headerImg}
                    alt="headerImg"
                    style={{
                        width: "70%",
                        marginBottom: -15,
                    }}
                />
            </ImageBox> */}
        </CustomBox>
    )
}

export default Header;
