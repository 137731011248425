import React from 'react';
import { Typography, Box, Stack, IconButton } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Jimmy from '../assets/team/Jimmy.jpg';
import Haashim from '../assets/team/Haashim.jpg';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Team = () => {
    return (
        <Box
            component='section'
            sx={{
                backgroundColor: '#060f5e',
                py: 8,
                px: 2,
                textAlign: 'center',
                color: 'white'
            }}
        >
            <Typography variant="h4" sx={{ mb: 4 }}>
                Our Team
            </Typography>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={20}
                justifyContent="center"
                alignItems="center"
            >
                <Box sx={{ textAlign: 'center' }}>
                    <img src={Jimmy} alt="Jimmy" style={{ width: '150px',  borderRadius: '50%' }} />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Jimmy Yan
                    </Typography>
                    <Typography variant="body1">
                        CEO and Co-Founder
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <IconButton component="a" href="mailto:jimmyyan1337@gmail.com" sx={{ color: 'white' }}>
                            <EmailIcon />
                        </IconButton>
                        <IconButton component="a" href="https://www.linkedin.com/in/jimmy-yan-jy/" target="_blank" sx={{ color: 'white' }}>
                            <LinkedInIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <img src={Haashim} alt="Haashim" style={{ width: '150px', borderRadius: '50%' }} />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Haashim Rehan
                    </Typography>
                    <Typography variant="body1">
                        CTO and Co-Founder
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <IconButton component="a" href="mailto:haashimrehan@gmail.com" sx={{ color: 'white' }}>
                            <EmailIcon />
                        </IconButton>
                        <IconButton component="a" href="https://www.linkedin.com/in/haashimrehan/" target="_blank" sx={{ color: 'white' }}>
                            <LinkedInIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Stack>
        </Box>
    );
};

export default Team;
