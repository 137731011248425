import React from 'react'
import { 
  Box, 
  Stack, 
  styled, 
  Typography,
} from '@mui/material'
import Link from '@mui/material/Link';
import FooterTitle from './FooterTitle'
import FooterLink from './FooterLink'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {

  const StackColumn = styled(Stack) (() => ({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    gap: 8,
    textAlign: 'center',
  }));

  const BoxRow = styled(Box) (({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ededed',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 30,
    }
  }));

  return (
    
    <BoxRow 
    component = 'footer'
    sx={{
      py: 4,
      px: 2,
    }}
    >
      <StackColumn>
        <FooterTitle text={'address'} />
        <FooterLink 
        text={'175 Longwood Rd S Suite 101A'}
        />
        <FooterLink 
        text={'Hamilton, ON L8P 0A1'} 
        />
      </StackColumn>
      
      <StackColumn>
        <FooterTitle text={'Contact Us'} />
        <FooterLink text={"info@mazedynamics.com"}></FooterLink>
        <FooterLink text={'1-647-463-5156'} />
      </StackColumn>

      <StackColumn>
        <FooterTitle text={'Maze Dynamics'} />
        <Typography 
        variant='caption'
        component='p' 
        >
          &copy; 2024 Maze Dynamics Inc.
        </Typography>
      </StackColumn>
    </BoxRow>
  )
}

export default Footer