import React from 'react'
import Content from '../components/Content';
import Gallery from '../components/Gallery';
import Team from '../components/Team';
import Partners from '../components/Partners';

const About = () => {
  return (
    <>
    <Content />
    {/* <Gallery /> */}
    <Team/>
    <Partners/>
    </>
  )
}

export default About