import { initializeApp} from "firebase/app";
import { getDatabase, ref, set} from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCrFz8MG8s2KIf31g9juxfqlAeIw-T_A6A",
    authDomain: "jy-web-6e1b0.firebaseapp.com",
    databaseURL: "https://jy-web-6e1b0-default-rtdb.firebaseio.com",
    projectId: "jy-web-6e1b0",
    storageBucket: "jy-web-6e1b0.appspot.com",
    messagingSenderId: "485725891787",
    appId: "1:485725891787:web:692a863ec6bc00ec94bf4e",
    measurementId: "G-J22ZX58TK8"
};

const firebase = initializeApp(firebaseConfig);

const database = getDatabase(firebase);
const dbref = ref(database, 'users/');

export const writeUserData = (email, phone) => {
    const dbref = ref(database, 'users/' +email);
    set(dbref, {email: email, phone: phone});
};

export{dbref, firebase}