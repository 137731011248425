import React from 'react'
import GetInTouch from '../components/GetInTouch';
import TradeIn from '../components/TradeIn';
import GetStarted from '../components/GetStarted';
import Solutions from '../components/Solutions';
import Header from '../components/Header';

const Home = () => {
  return (
    <>
    <Header />
    <GetStarted />
    <TradeIn />
    {/* <Solutions/> */}
    </>

  )
}

export default Home